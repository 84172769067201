var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"email-products-comparion"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-form"},[_c('button',{staticClass:"close",attrs:{"data-dismiss":"modal"}}),(_vm.message)?_c('div',{staticClass:"alert mb-4",class:_vm.alertClass,attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('h5',[_vm._v("Email product information")]),(_vm.profileType === 'engineer')?_c('div',[_c('p',{staticClass:"mb-2"},[_vm._v(" Enter customer's name, job number & email to send product information. ")]),_vm._m(0)]):_c('p',[_vm._v(" Enter customer's name & email to send product information ")]),_c('ValidationObserver',{ref:"emailForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitEmailForm($event)}}},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control",class:{
                    'is-invalid': v.invalid && v.validated,
                  },attrs:{"type":"text","placeholder":"Name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback text-left"},[_vm._v(" "+_vm._s(v.errors[0])+" ")])]}}],null,true)})],1),(_vm.profileType === 'engineer')?_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Job Number","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.jobNumber),expression:"jobNumber"}],staticClass:"form-control",class:{
                    'is-invalid': v.invalid && v.validated,
                  },attrs:{"type":"text","placeholder":"Job Number","maxlength":"10","pattern":"^237\\d{7}$"},domProps:{"value":(_vm.jobNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.jobNumber=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback text-left"},[_vm._v(" "+_vm._s(v.errors[0])+" ")])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"form-group"},[(_vm.profileType === 'engineer')?_c('ValidationProvider',{attrs:{"name":"D&G Job","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dAndGJob),expression:"dAndGJob"}],staticClass:"custom-select form-control form-control-lg",class:{ 'is-invalid': v.invalid && v.validated },attrs:{"name":"D&G Job"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.dAndGJob=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Select D&G Job")]),_c('option',{domProps:{"value":0}},[_vm._v("D&G Job")]),_c('option',{domProps:{"value":1}},[_vm._v("D&G Job New Plan")])]),_c('div',{staticClass:"invalid-feedback text-left"},[_vm._v(" "+_vm._s(v.errors[0] ? "D&G job selection is required" : "")+" ")])]}}],null,true)}):_vm._e()],1),_c('ValidationProvider',{attrs:{"name":"E-mail","rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                    'is-invalid': v.invalid && v.validated,
                  },attrs:{"type":"email","placeholder":"Email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn btn-alt btn-primary theme-bg",attrs:{"type":"submit","disabled":invalid || _vm.profileFormLoading,"id":"compare-products-email-button"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner-grow spinner-grow-sm",staticStyle:{"width":"1.0625rem","height":"1.0625rem"},attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Send ")])]),_c('div',{staticClass:"invalid-feedback text-left"},[_vm._v(" "+_vm._s(v.errors[0])+" ")])])]}}],null,true)})],1)]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Please remind the customer: ")]),_vm._v("They will receive a courtesy call from our team to check in following their repair as they can support with a replacement appliance. ")])}]

export { render, staticRenderFns }